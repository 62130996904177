import { Box, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { makeStyles } from '../../core/utils/theme'
import DataObjectEntries from './DataObjects'
import useApp from '../../hooks/useApp'
import { observer } from 'mobx-react'
import useSettings from '../../hooks/useSettings'
import { TourType } from '../../core/types/source_service/IGuideTour'
import { isEndUserView } from '../../core/utils/main'
import Logo2 from '../Misc/Logo2'
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import DataSourcesModal from '../DataSources/DataSourcesModal'
import DataSourceManagerModal from '../DataSources/DataSourceManagerModal'
import DescriptionsModal from '../Modals/DescriptionsModal'
import DatacakesModal from '../Datacakes/DatacakesModal'
import CreateDatacakeModal from '../CakeBaking/CreateDatacakeModal'
import { LOGIN_URL } from '../../core/config/main'
import ShareModal from '../Modals/ShareModal'
import MainMenu from './MainMenu'
import loadinggif from '../../assets/loading.gif'

const useStyles = makeStyles()(() => ({
  root: {
    padding: '20px',
    display: 'flex',
    height: '100%',
    overflow:'auto',
    width: '325px',
    borderRight: '1px solid grey',
    maxWidth: '325px',
    minWidth:'325px',
    flexDirection: 'column',
    justifyContent:'space-between',
    position: 'relative',
    zIndex: 1
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1
  },
  logoBloc: {
    position:'relative',
    height:'50px',
    width:'100%',
    marginBottom:'10px'
  },
  logo: {
    position:'absolute',
    top:'0px',
    left:'0px',
    width:'50px',
    height:'50px',
  },
  userBloc: {
    position:'absolute',
    top:'0px',
    left:'50px',
    right:'0px',
    bottom:'0px',
    textAlign:'right',
  },
  email: {
    width:'100%',
    textOverflow:'ellipsis',
    overflow:'hidden',
    padding:'0 0 0 15px'
  },
  logoutButton: {
    visibility:'hidden',
    cursor:'pointer',
    height:'14px',
    width:'14px',
    marginRight:'3px'
  },
  spacer: {
    height:'20px',
  },
  hr: {
    height:'0px',
    borderBottom: '1px grey solid',
    margin:'10px 0'
  },
  navBloc: {
    margin: '10px 0 0px',
  },
  navItem: {
    margin: '10px 0',
    padding: '0 5px',
    cursor:'pointer'
  },
  selected: {
    border: '1px solid black',
    borderRadius: '4px'
  },
  dataBloc: {
    margin: '10px 0',
    zIndex: '10'
  },
  modelRoot: {
    paddingLeft: '0.5rem',
    paddingTop: '0.5rem',
    zIndex: '10',
    // '&:hover': {
    //   background: theme.palette.primary.main,
    //   color: theme.palette.text.secondary,
    //borderRadius: 4,
    // },
  },
  modelName: {
    display: 'block',
    whiteSpace: 'nowrap', // truncate text
    // overflow: 'hidden', // // truncate text
    textOverflow: 'ellipsis',
    maxWidth: '100%', // or set a specific width according to your layout
  },

}))

const LeftSidebar2: FC = observer(() => {
  const { classes, cx } = useStyles()

  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const email = user.userEmail || ''

  const [isSourceTableClosed, setIsSourceTableClosed] = useState(false)

  const navigate = useNavigate()

  const navClick = (slug: string) => {
    navigate(isEndUserView() ? `/${slug}?cakeId=${app.cakeId}` : `/${slug}` )
  }

  useEffect(() => {
    if (!app.cakeId)
      setIsSourceTableClosed(true)
    setSourceTabClass()
    if (app.coreDataObjects.length === 0) return
  }, [isSourceTableClosed, app.coreDataObjects])

  useEffect(() => {
    if (settings.tourType !== TourType.NONE) {
      setIsSourceTableClosed(true);
    }
  }, [settings.tourType, settings.tourSteps])

  function setSourceTabClass() {
    const elt = document.getElementById("srcTables")
    if (elt && (elt.classList.contains('-translate-x-full') != isSourceTableClosed))
      elt.classList.toggle('-translate-x-full')

  }

  return (
    <>
    <ShareModal open={settings.isShareModalOpen} onClose={() => { settings.setIsShareModalOpen(false) }} />
    {user.isAuthenticated() && !isEndUserView() && <DescriptionsModal />}
    {user.isAuthenticated() && !isEndUserView() && <DatacakesModal />}
    {user.isAuthenticated() && !isEndUserView() && <CreateDatacakeModal />}
    {user.isAuthenticated() && !isEndUserView() && <DataSourcesModal />}
    {user.isAuthenticated() && !isEndUserView() && <DataSourceManagerModal />}
    <Box className={classes.root}>
      <Box className={classes.root2}>
        <Box className={classes.logoBloc}>
          <Box className={classes.logo}>
            <Logo2 />
          </Box>
          <Box className={classes.userBloc}>
            { user.isAuthenticated() && user.userEmail ? (
              <>
                <Typography variant='caption' style={{fontFamily:'helvetica', fontWeight:'normal'}}>
                  Logged in as:
                </Typography>
                <br/>
                <div className={classes.email} style={{textAlign:'right', fontWeight:'500'}}
                  // onMouseOver={()=>{const e = document.getElementById('logoutButton'); if (e) e.style.visibility = 'visible';}}
                  // onMouseOut={()=>{const e = document.getElementById('logoutButton'); if (e) e.style.visibility = 'hidden';}}
                  >
                  {isEndUserView() ? email : <MainMenu text={email} />}
                </div>
              </>
            ) : (
              <>
                <Typography variant='caption' style={{fontFamily:'helvetica', fontWeight:'normal'}}>
                  Not logged in
                </Typography>
                <br/>
                <div className={classes.email} style={{textAlign:'right', fontWeight:'500'}}>
                  <span className="cursor-pointer underline" onClick={()=>{window.location.href=LOGIN_URL}}>Login</span>
                </div>
              </>
            )}
          </Box>
        </Box>
        {/* <Box className={classes.titleBloc}>
          {app.title}
        </Box> */}
        <div className={classes.hr}/>
        <Box className={classes.navBloc}>
          <Typography variant='body2' style={{fontFamily:'helvetica', fontWeight:'bold', background:'black', color:'white', paddingLeft:'4px'}} >
          {/* <Typography variant='body2' style={{fontFamily:'helvetica', fontWeight:'bold'}} > */}
            Our Tools
          </Typography>
          <div id="dashboardButton" className={window.location.pathname.includes('dashboard') ? cx(classes.navItem, classes.selected) : classes.navItem} onClick={()=>{navClick('dashboard')}}>Dashboard</div>
          <div className={window.location.pathname.includes('ask') ? cx(classes.navItem, classes.selected) : classes.navItem} onClick={()=>{navClick('ask')}}>Ask Cubie</div>
        </Box>
        <div className={classes.hr}/>
        <Box className={classes.dataBloc}>
          <Typography variant='body2' style={{fontFamily:'helvetica', fontWeight:'bold', background:'black', color:'white', paddingLeft:'4px'}} >
          {/* <Typography variant='body2' style={{fontFamily:'helvetica', fontWeight:'bold'}} > */}
            Source Data {app.isInitializing && <img src={loadinggif} style={{display:'inline', height:'20px'}}/>}
          </Typography>
          <DataObjectEntries />
        </Box>

        <div className={classes.hr}/>

        {user.userEmail && user.userEmail.includes("@datacakes.ai") && (
          <Box className={classes.dataBloc}>
            <Typography variant='body2' style={{fontFamily:'helvetica', fontWeight:'bold', background:'black', color:'white', paddingLeft:'4px'}} >
            {/* <Typography variant='body2' style={{fontFamily:'helvetica', fontWeight:'bold'}} > */}
              Models
            </Typography>
            <Box className={classes.modelRoot}>
              <Box className={classes.modelName}>
                &#x2325; attribution_2024_08_20<br/>
              </Box>
            </Box>
            <Box className={classes.modelRoot}>
              <Box className={classes.modelName}>
                &#x2325; forecaster_2024_02_24<br/>
              </Box>
            </Box>
            <Box className={classes.modelRoot}>
              <Box className={classes.modelName}>
                &#x2325; anomaly_alert_2024_06_02<br/>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box className="text-center text-black text-xs">
        <a className="text-black text-xs" target="_blank" href="https://www.datacakes.ai/privacy">Privacy policy</a>
        <div style={{height:'10px'}}></div>
        &copy; {(new Date()).getFullYear()} Datacakes Inc.
      </Box>
    </Box>
    </>
  )
})

export default LeftSidebar2
